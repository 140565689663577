enum ERoute {
  // Email confirmation 
  PATH_CONFIRM_EMAIL = '/confirm-email',
  // Welcome layout
  PATH_WELCOME_LAYOUT = '/welcome',
  // Preview layout
  PATH_PREVIEW_LEDGER_ITEM = '/preview/invoice/:id',
  // Workspace layout
  PATH_WORKSPACES = '/workspaces',
  // Subscription paywall
  PATH_SUBSCRIPTION_PAYWALL = '/subscription',
  // App layout
  PATH_APP_LAYOUT = '/',
  PATH_DASHBOARD = '/',
  PATH_CALENDAR = '/calendar',
  PATH_TASKS_OVERVIEW = '/tasks/overview',
  PATH_TASKS_MY_TASKS = '/tasks/my-tasks',
  PATH_TASKS_TODAY = '/tasks/today',
  PATH_TASKS_UPCOMING = '/tasks/upcoming',
  PATH_TASKS_PLANNER = '/tasks/planner',
  PATH_TASKS_BOARD = '/tasks/board/:id',
  PATH_TASKS = '/tasks',
  PATH_CONTACTS = '/contacts',
  PATH_DEAL = '/deal/:id',
  PATH_DEALS = '/deals',
  PATH_CONTACT = '/contact/:id',
  PATH_CONTACT_TASKS = '/contact/:id/tasks',
  PATH_CONTACT_BOARDS = '/contact/:id/boards',
  PATH_CONTACT_DEALS = '/contact/:id/deals',
  PATH_CONTACT_PROJECTS = '/contact/:id/projects',
  PATH_CONTACT_TIMESHEETS = '/contact/:id/timesheets',
  PATH_CONTACT_ORDER_FORMS = '/contact/:id/order-forms',
  PATH_CONTACT_DELIVERY_NOTES = '/contact/:id/delivery-notes',
  PATH_CONTACT_QUOTATIONS = '/contact/:id/quotations',
  PATH_CONTACT_PRO_FORMA_INVOICES = '/contact/:id/pro-forma-invoices',
  PATH_CONTACT_INVOICES = '/contact/:id/invoices',
  PATH_CONTACT_PURCHASE_INVOICES = '/contact/:id/purchase-invoices',
  PATH_CONTACT_CREDIT_NOTES = '/contact/:id/credit-notes',
  PATH_CONTACT_RECURRING_INVOICES = '/contact/:id/recurring-invoices',
  PATH_CONTACT_EXPENSES = '/contact/:id/expenses',
  PATH_CONTACT_PROPOSALS = '/contact/:id/proposals',
  PATH_CONTACT_CONTRACTS = '/contact/:id/contracts',
  PATH_CONTACT_FILES = '/contact/:id/files',
  PATH_PROJECTS = '/projects/',
  PATH_PROJECTS_ACTIVE = '/projects/active',
  PATH_PROJECTS_PROPOSAL = '/projects/proposal',
  PATH_PROJECTS_COMPLETED = '/projects/completed',
  PATH_PROJECTS_CANCELLED = '/projects/cancelled',
  PATH_PROJECT_TASKS = '/project/:id/tasks',
  PATH_PROJECT_BOARDS = '/project/:id/boards',
  PATH_PROJECT_DEALS = '/project/:id/deals',
  PATH_PROJECT_TIMESHEETS = '/project/:id/timesheets',
  PATH_PROJECT_ORDER_FORMS = '/project/:id/order-forms',
  PATH_PROJECT_DELIVERY_NOTES = '/project/:id/delivery-notes',
  PATH_PROJECT_QUOTATIONS = '/project/:id/quotations',
  PATH_PROJECT_PRO_FORMA_INVOICES = '/project/:id/pro-forma-invoices',
  PATH_PROJECT_INVOICES = '/project/:id/invoices',
  PATH_PROJECT_CREDIT_NOTES = '/project/:id/credit-notes',
  PATH_PROJECT_RECURRING_INVOICES = '/project/:id/recurring-invoices',
  PATH_PROJECT_EXPENSES = '/project/:id/expenses',
  PATH_PROJECT_PROPOSALS = '/project/:id/proposals',
  PATH_PROJECT_CONTRACTS = '/project/:id/contracts',
  PATH_PROJECT_FILES = '/project/:id/files',
  PATH_PROJECT = '/project/:id',

  PATH_TIMESHEETS = '/timesheets',
  PATH_TIMESHEETS_OVERVIEW = '/timesheets/overview',
  PATH_TIMESHEETS_REPORTING = '/timesheets/reporting',
  PATH_PROPOSAL = '/proposals/:id',
  PATH_PROPOSALS = '/proposals',
  PATH_CONTRACT = '/contracts/:id',
  PATH_CONTRACTS_CREATE = '/contracts/create',
  PATH_CONTRACTS = '/contracts',
  PATH_PLAYBOOK = '/playbooks/:id',
  PATH_PLAYBOOKS_CREATE = '/playbooks/create',
  PATH_PLAYBOOKS = '/playbooks',
  PATH_ORDERS = '/orders',
  PATH_ORDER_FORMS = '/orders/order-forms',
  PATH_DELIVERY_NOTES = '/orders/delivery-notes',
  PATH_QUOTATIONS = '/orders/quotations',
  PATH_PRO_FORMA_INVOICES = '/orders/pro-forma-invoices',
  PATH_INVOICES = '/orders/invoices',
  PATH_CREDIT_NOTES = '/orders/credit-notes',
  PATH_RECURRING_INVOICES = '/orders/recurring-invoices',
  PATH_INVOICES_NEW = '/invoices/new',
  PATH_LEDGER_CONDITION_NEW = '/ledger-item-conditions/new',
  PATH_LEDGER_CONDITION = '/ledger-item-conditions/:id',
  PATH_LEDGER_CONDITIONS = '/ledger-item-conditions',
  PATH_INVOICE_EDIT = '/invoice/:id/edit',
  PATH_INVOICE = '/invoice/:id',
  PATH_PRODUCTS = '/products',
  PATH_FORM_RESPONSE = '/form/:id/responses/:responseId',
  PATH_FORM_RESPONSES = '/form/:id/responses',
  PATH_FORM = '/form/:id',
  PATH_FORMS_CREATE = '/forms/create',
  PATH_FORMS = '/forms',
  PATH_FILES = '/files',
  PATH_EXPENSES = '/expenses',
  PATH_TRANSACTION = '/transaction/:id',
  PATH_BANK = '/bank',
  PATH_BANK_ACCOUNTS = '/bank/accounts',
  PATH_BANK_TRANSACTIONS = '/bank/transactions',
  PATH_BANK_PAYMENTS = '/bank/payments',
  PATH_ACCOUNTANT = '/accountant',
  PATH_ACCOUNTANT_SEND = '/accountant/send',
  PATH_ACCOUNTANT_DOCUMENTS = '/accountant/documents',
  PATH_INTEGRATION = '/integrations/:type',
  PATH_INTEGRATIONS = '/integrations',
  PATH_ACCOUNT = '/account',
  PATH_ACCOUNT_PROFILE = '/account/profile',
  PATH_ACCOUNT_PROFILE_DETAILS = '/account/profile/details',
  PATH_ACCOUNT_PROFILE_NOTIFICATIONS = '/account/profile/notifications',
  PATH_ACCOUNT_PROFILE_SECURITY = '/account/profile/security',
  PATH_ACCOUNT_SUBSCRIPTION = '/account/subscription',
  PATH_SETTINGS = '/settings/',
  PATH_SETTINGS_GENERAL = '/settings/general',
  PATH_SETTINGS_COMPANY = '/settings/company',
  PATH_SETTINGS_COMPANY_BRANDING = '/settings/company-branding',
  PATH_SETTINGS_COMPANY_INFO = '/settings/company',
  PATH_SETTINGS_DISPLAY_PREFERENCES = '/settings/display-preferences',
  PATH_SETTINGS_CALENDAR = '/settings/calendar',
  PATH_SETTINGS_CONTACTS = '/settings/contacts',
  PATH_SETTINGS_ORDER_FORMS = '/settings/order-forms',
  PATH_SETTINGS_DELIVERY_NOTES = '/settings/delivery-notes',
  PATH_SETTINGS_QUOTATIONS = '/settings/quotations',
  PATH_SETTINGS_PRO_FORMA_INVOICES = '/settings/pro-forma-invoices',
  PATH_SETTINGS_INVOICES = '/settings/invoices',
  PATH_SETTINGS_CREDIT_NOTES = '/settings/credit-notes',
  PATH_SETTINGS_EMAIL = '/settings/email',
  PATH_SETTINGS_CUSTOM_FIELDS_MODEL = '/settings/custom-fields/:model',
  PATH_SETTINGS_CUSTOM_FIELDS = '/settings/custom-fields',
  PATH_SETTINGS_MENU_EDITOR = '/settings/menu-editor',
  PATH_SETTINGS_WORK_TYPES = '/settings/work-types',
  PATH_SETTINGS_SMTP = '/settings/smtp',
  PATH_SETTINGS_DATA = '/settings/data',
  PATH_TEAM = '/team',
  PATH_REPORTING = '/reporting',
  PATH_REPORTING_REVENUE = '/reporting/revenue',
  PATH_REPORTING_EXPENSES = '/reporting/expenses',
  PATH_REFER_A_FRIEND = '/refer-a-friend',
  PATH_SEQUENCE = '/sequence/:id',
  PATH_SEQUENCE_OVERVIEW = '/sequence/:id/overview',
  PATH_SEQUENCE_ENROLLMENTS = '/sequence/:id/enrollments',
  PATH_SEQUENCE_SETTINGS = '/sequence/:id/settings',
  PATH_SEQUENCES_CREATE = '/sequences/create',
  PATH_SEQUENCES = '/sequences',
}

export default ERoute