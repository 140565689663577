import * as React from 'react'
import ResourceSelectComponents from './ResourceSelectComponents'
import { components } from 'react-select'
import { SelectComponents } from 'react-select/src/components'
import styled from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'
import MultiValueContainer from './Parts/MultiValueContainer'
import ValueContainer from './Parts/ValueContainer'
import ValueActions from './Parts/ValueActions'
import ValueAction from './Parts/ValueAction'
import OptionContainer from './Parts/OptionContainer'
import { Contact, ContactType } from '../../types'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

const StarContact = styled.div<{ starred: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${props => props.starred ? '#f4a230' : 'black'};
  margin-left: 4px;

  svg {
    width: 14px;
    height: 14px;
    fill: currentColor;
  }
`

const getIcon = (contact: Contact): IconTypes => {
  return contact?.type === ContactType.COMPANY ? 'company' : 'user'
}

const ParentContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ContactContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  margin-right: 4px;
`

const ContactSelectComponents: Partial<SelectComponents<any, any>> = {
  ...ResourceSelectComponents,
  Option: (props) => {
    const { data: { label, contact, __isNew__, placeholder } } = props

    if (placeholder) return null

    if (!__isNew__) {
      return (
        <components.Option {...props}>
          <OptionContainer>
            <Container>
              {contact?.contact && <>
                <ParentContactContainer>
                  <IconContainer>
                    <Icon icon={getIcon(contact?.contact)} />
                  </IconContainer>
                  <ContactContainer>
                    {contact?.contact?.name}
                  </ContactContainer>
                </ParentContactContainer>

                →
              </>}


              <ContactContainer>
                <IconContainer>
                  <Icon icon={getIcon(contact)} />
                </IconContainer>
                {label}
                {contact?.starred && <StarContact starred={true}>
                  <Icon icon='star' />
                </StarContact>}
              </ContactContainer>
            </Container>
          </OptionContainer>
        </components.Option >
      )
    } else {
      return (
        <components.Option {...props}>
          {label}
        </components.Option>
      )
    }
  },
  SingleValue: (props) => {
    const { data: { label, contact }, isMulti } = props

    return (
      <components.SingleValue {...props}>
        <Container>
          {contact?.contact && <>
            <ValueContainer>
              <IconContainer>
                <Icon icon={getIcon(contact?.contact)} />
              </IconContainer>
              <ContactContainer>
                {contact?.contact?.name}
              </ContactContainer>
            </ValueContainer>
            →
          </>}

          <ValueContainer isMulti={isMulti}>
            <IconContainer>
              <Icon icon={getIcon(contact)} />
            </IconContainer>

            <ContactContainer>
              {label}
            </ContactContainer>

            {contact?.starred && <StarContact starred={true}>
              <Icon icon='star' />
            </StarContact>}
          </ValueContainer>
        </Container>
      </components.SingleValue>
    )
  },
  MultiValue: (props) => {
    const {
      data: {
        label,
        contact,
      },
      selectProps: {
        upsertModeEnabled,
        onEditClick
      },
      isDisabled
    } = props

    return (
      <components.MultiValue {...props}>
        <MultiValueContainer>
          <Container>
            {contact?.contact && <>
              <ValueContainer>
                <IconContainer>
                  <Icon icon={getIcon(contact?.contact)} />
                </IconContainer>
                <ContactContainer>
                  {contact?.contact?.name}
                </ContactContainer>
              </ValueContainer>
              →
            </>}

            <ValueContainer isMulti>
              <IconContainer>
                <Icon icon={getIcon(contact)} />
              </IconContainer>
              <span>{label}</span>
            </ValueContainer>
          </Container>

          {!isDisabled && upsertModeEnabled && <ValueActions>
            <ValueAction onClick={(e) => onEditClick(e, props.data)} onTouchStart={(e) => onEditClick(e, props.data)}>
              <Icon icon='pen' />
            </ValueAction>
          </ValueActions>}
        </MultiValueContainer>
      </components.MultiValue>
    )
  },
}

export default ContactSelectComponents
