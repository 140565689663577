import styled from 'styled-components'
import { Style } from '../../styles'

const Card = styled.div`
	position: relative;
	background-color: white;
	border: 1px solid ${Style.color.border};
	width: 100%;
	border-radius: 8px;

	.panel & {
		border: none;
	}

	@media screen and (max-width: ${Style.breakpoints.SMALL}) {
		border-radius: 0;
	}
`
export default Card