import { ThemeConfig } from "react-select/src/theme";
import { Theme } from "react-select/src/types";


const ReactSelectTheme = (theme: Theme): Theme => {
  return {
    borderRadius: theme.borderRadius,
    spacing: {
      ...theme.spacing,
    },
    colors: {
      ...theme.colors,
      primary: 'rgba(67, 137, 252, 1)',
      // primary75: 'rgba(67, 137, 252, 0.75)',
      // primary50: 'rgba(67, 137, 252, 0.5)',
      // primary25: 'rgba(67, 137, 252, 0.25)',

      danger: '#ff4040',
      dangerLight: '#FFBDAD',

      // neutral0: 'hsl(0, 0%, 100%)',
      // neutral5: 'hsl(0, 0%, 95%)',
      // neutral10: 'hsl(0, 0%, 90%)',
      neutral20: '#c4cdd5',
      neutral30: '#c4cdd5',
      neutral50: 'rgba(0, 0, 0, .35)',
      // neutral60: 'hsl(0, 0%, 40%)',
      // neutral70: 'hsl(0, 0%, 30%)',
      // neutral80: '#ebedf2',
      // neutral90: 'hsl(0, 0%, 10%)',
    }
  }
}

export default ReactSelectTheme