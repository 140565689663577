import { toast } from 'react-toastify'

export default class Notification  {
	static notifySuccess(text: string, callback = () => {}) {
		toast.success(text, { onClick: callback })
	}

	static notifyWarning(text: string, callback = () => {}) {
		toast.warn(text, { onClick: callback })
	}

	static notifyError(text: string, callback = () => {}) {
		toast.error(text, { onClick: callback })
	}

	static notifyInfo(text: string, callback = () => {}) {
		toast.info(text, { onClick: callback })
	}
}