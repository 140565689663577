import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div`
  background: white;
  border-radius: 3px;
  padding: 8px 0;
  min-width: 200px;
  max-width: calc(100vw - 3.2rem);
  box-shadow: 0 0 0 1px rgba(6, 44, 82, 0.1), 0 2px 16px rgba(33, 43, 54, 0.08);
  border-radius: 3px;
  backface-visibility: hidden;
  will-change: opacity,left,top;
`

const List = styled.ul`
	list-style: none;
`

const ListItem = styled.li<{ destructive?: boolean }>`
  appearance: none;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: none;
  width: 100%;
  min-height: 40px;
  padding: 10px 16px;
  text-align: left;
  cursor: pointer;
  border-radius: 0;
  transition: background .2s;
  transition-timing-function: cubic-bezier(.64,0,.35,1);
  font-weight: normal;
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;

  ${(props: { destructive?: boolean }) => props.destructive && css`
    color: ${Style.color.brandDanger};
  `}

  .is-page-action & {
    font-size: 15px;
  }

  &:hover, &:focus, &:active {
    background-image: linear-gradient(rgba(223,227,232,.3),rgba(223,227,232,.3));
    
    /* .has-indicator & {
      box-shadow: inset 2px 0 0 ${Style.color.brandPrimary};

      ${(props: { destructive?: boolean }) => props.destructive === true && css`
        box-shadow: inset 2px 0 0 ${Style.color.brandDanger};
      `}

      @media screen and (max-width: ${Style.breakpoints.SMALL}) {
        box-shadow: none;
      }
    } */
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;

  i, svg {
    font-size: 16px;
    width: 16px !important;
    height: 16px !important;
  }
`

interface IProps {
  actions: IActionListItem[]
  onClick?: (key: string) => void
  hasIndicator?: boolean
}

export interface IActionListItem {
  key: string
  content: string
  icon?: IconTypes
  destructive?: boolean
  visible?: boolean
  onClick?: () => void
}

export default class ActionList extends React.PureComponent<IProps> {
  static defaultProps = {
    indicator: false
  }

  render() {
    const { actions, hasIndicator, onClick } = this.props

    const containerClassName = hasIndicator ? 'has-indicator' : ''
    return (
      <Container className={containerClassName}>
        <List>
          {actions.filter(action => action.visible !== false).map(action => {
            return (
              <ListItem key={action.key} destructive={action.destructive} onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                if (action.onClick) {
                  action.onClick()

                  // Needs onClick handler
                  if (onClick) onClick('')
                  // Deprecate this way of working in favor of defining the onClick on the action
                } else if (onClick) {
                  onClick(action.key)
                }
              }
              }>
                {action.icon && <IconContainer><Icon icon={action.icon} /></IconContainer>}
                {action.content}
              </ListItem>
            )
          })}
        </List>
      </Container>
    )
  }
}