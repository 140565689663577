import * as React from 'react'
import styled, { css } from "styled-components"
import { Style } from '../../styles'

interface IProps {
	checked: boolean
	disabled?: boolean
	partial?: boolean
	onCheckedChange?: (checked: boolean) => void
}

const Container = styled.div<{ disabled?: boolean }>`
	display: flex;
	position: relative;
	width: 18px;
	height: 18px;
	cursor: pointer;

	${props => props.disabled && css`
		cursor: initial;
	`}
`

const Label = styled.div`
	position: relative;
	display: inline-flex;
	padding: 0;
	width: 100%;
	height: 100%;
	width: 18px;
	height: 18px;
	min-width: 18px;
	min-height: 18px;
`

const LabelWrapper = styled.div`
	position: relative;
	margin: 1px;
	width: 100%;
	height: 100%;
	width: 18px;
	height: 18px;
	min-width: 18px;
	min-height: 18px;
`

const CheckboxInput = styled.input`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	margin: 0;
`

const Backdrop = styled.div<{ active?: boolean, disabled?: boolean }>`
	position: relative;
	border: 2px solid ${Style.color.border};
	background: white;
	border-radius: 4px;
	display: block;
	width: 100%;
	height: 100%;

	&::after {
		content: '';
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-radius: 4px;
    background-color: #2c6ecb;
    opacity: 0;
    transform: scale(0.25);
    transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

		${props => props.disabled && css`
			opacity: 1;
			transform: scale(1);
		`}

		${props => props.active && css`
			opacity: 1;
			transform: scale(1);
		`}
	}

	&::before {
		content: '';
    position: absolute;
    z-index: 1;
    top: -3px;
    right: -3px;
    bottom: -3px;
    left: -3px;
    display: block;
    pointer-events: none;
    box-shadow: 0 0 0 -3px rgba(69, 143, 255, 1);
    transition: box-shadow 100ms cubic-bezier(0.25, 0.1, 0.25, 1);
    border-radius: 5px;

		${props => props.active && css`
			opacity: 1;
			transform: scale(1);
		`}
	}
`

const IconContainer = styled.div<{ active?: boolean }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform-origin: 50% 50%;
	pointer-events: none;
	transform: translate3d(-50%, -50%, 0) scale(0.25);
	opacity: 0;
	transition: opacity 100ms cubic-bezier(0.25, 0.1, 0.25, 1), transform 100ms cubic-bezier(0.25, 0.1, 0.25, 1);

	${props => props.active && css`
		opacity: 1;
		transform: translate3d(-50%, -50%, 0) scale(1);
	`}

	svg {
		fill: white;
		position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
	}
`

const IconWrapper = styled.div`
	display: block;
	height: 18px;
	width: 18px;
	max-height: 100%;
	max-width: 100%;
	margin: auto;
`

const ResourceCheckbox = (props: IProps) => {
	const { checked, disabled, partial } = props

	const onCheckedChange = () => {
		if (props.onCheckedChange) props.onCheckedChange(!Boolean(checked))
	}

	return (
		<Container onClick={onCheckedChange}>
			<Label>
				<LabelWrapper>
					<CheckboxInput type='checkbox' />
					<Backdrop active={checked} disabled={disabled} />
					<IconContainer active={checked}>
						<IconWrapper>
							{/* -- STRIPE -- */}
							{checked && partial && <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M14.167 9h-8.334c-.46 0-.833.448-.833 1s.372 1 .833 1h8.334c.46 0 .833-.448.833-1s-.373-1-.833-1"></path></svg>}
							{/* -- V -- */}
							{checked && !partial && <svg viewBox="0 0 20 20" focusable="false" aria-hidden="true"><path d="M14.723 6.237a.94.94 0 0 1 .053 1.277l-5.366 6.193a.834.834 0 0 1-.611.293.83.83 0 0 1-.622-.264l-2.927-3.097a.94.94 0 0 1 0-1.278.82.82 0 0 1 1.207 0l2.297 2.43 4.763-5.498a.821.821 0 0 1 1.206-.056Z"></path></svg>}
						</IconWrapper>
					</IconContainer>
				</LabelWrapper>
			</Label>
		</Container>
	)
}

export default ResourceCheckbox