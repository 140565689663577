import i18n from "../translations";
import moment from "./Moment";
import parse from "parse-duration";

parse["maand"] = parse["months"];
parse["maanden"] = parse["months"];
parse["week"] = parse["w"];
parse["weken"] = parse["w"];
parse["dag"] = parse["d"];
parse["dagen"] = parse["d"];
parse["dag"] = parse["d"];
parse["dagen"] = parse["d"];
parse["u"] = parse["h"];
parse["uur"] = parse["h"];
parse["uren"] = parse["h"];
parse["m"] = parse["m"];
parse["minuut"] = parse["m"];
parse["minuten"] = parse["m"];
parse["sec"] = parse["s"];
parse["seconde"] = parse["s"];
parse["seconden"] = parse["s"];

export default class TimeFormatter {
  static datetimeFormat(format, hourFormat = 24) {
    if (hourFormat == 24) {
      switch (format) {
        case "hh:mm:ss":
          return `HH:mm:ss`;
        case "hh mm ss":
          return `HH[${i18n.t("TimeFormatter::h")}] mm[${i18n.t(
            "TimeFormatter::m"
          )}] ss[${i18n.t("TimeFormatter::s")}]`;
      }
    } else if (hourFormat == 12) {
      switch (format) {
        case "hh:mm:ss":
          return `hh:mm:ss A`;
        case "hh mm ss":
          return `h[${i18n.t("TimeFormatter::h")}] m[${i18n.t(
            "TimeFormatter::m"
          )}] s[$${i18n.t("TimeFormatter::s")}] A`;
      }
    } else {
      throw Error("No valid hour format");
    }
  }

  static durationFormat(durationInSeconds, format, trim = false) {
    let options = {};

    if (trim === false) {
      options["trim"] = false;
    }

    switch (format) {
      case "hh:mm:ss":
        return moment
          .duration({ seconds: durationInSeconds })
          .format(`hh:mm:ss`, options);
      case "hh mm ss":
        return moment
          .duration({ seconds: durationInSeconds })
          .format(
            `h[${i18n.t("TimeFormatter::h")}]m[${i18n.t(
              "TimeFormatter::m"
            )}]s[${i18n.t("TimeFormatter::s")}]`,
            options
          );
    }
  }

  static durationFormatHighlighted(
    durationInSeconds,
    format,
    trim = false
  ): string {
    let options = {};

    if (trim === false) {
      options["trim"] = false;
    }

    switch (format) {
      case "hh:mm:ss":
        return moment
          .duration({ seconds: durationInSeconds })
          .format(`[<b>]hh[</b>]:[<b>]mm[</b>]:ss`, options);
      case "hh mm ss":
        return moment
          .duration({ seconds: durationInSeconds })
          .format(
            `[<b>]h[${i18n.t("TimeFormatter::h")}][</b>] [<b>]m[${i18n.t(
              "TimeFormatter::m"
            )}][</b>] s[${i18n.t("TimeFormatter::s")}]`,
            options
          );
    }
  }

  static parseDuration(text, format: parse.Units = "s") {
    return parse(text, format);
  }

  static formatToHumanReadableDuration(durationInSeconds: number) {
    return moment
      .duration(durationInSeconds, "seconds")
      .format(
        `d[${i18n.t("TimeFormatter::d")}] h[${i18n.t(
          "TimeFormatter::h"
        )}] m[${i18n.t("TimeFormatter::m")}] s[${i18n.t("TimeFormatter::s")}]`,
        { trim: "all" }
      );
  }
}
