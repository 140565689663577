import * as React from 'react'
import { components } from 'react-select'
import { SelectComponents } from 'react-select/src/components'
import styled from 'styled-components'
import Icon from '../Icons/Icon'
import MultiValueContainer from './Parts/MultiValueContainer'
import ValueContainer from './Parts/ValueContainer'
import ValueActions from './Parts/ValueActions'
import ValueAction from './Parts/ValueAction'
import IndicatorContainer from './Parts/IndicatorContainer'
import OptionContainer from './Parts/OptionContainer'

const ResourceSelectComponents: Partial<SelectComponents<any, any>> = {
  Option: (props) => {
    const { data: { label, __isNew__, placeholder } } = props

    if (placeholder) return null

    if (!__isNew__) {
      return (
        <components.Option {...props}>
          <OptionContainer>
            {label}
          </OptionContainer>
        </components.Option>
      )
    } else {
      return (
        <components.Option {...props}>
          {label}
        </components.Option>
      )
    }
  },
  SingleValue: (props) => {
    const { data: { label }, isMulti } = props

    return (
      <components.SingleValue {...props}>
        <ValueContainer isMulti={isMulti}>
          <span>
            {label}
          </span>
        </ValueContainer>
      </components.SingleValue>
    )
  },
  MultiValue: (props) => {
    const {
      data: {
        label,
      },
      selectProps: {
        upsertModeEnabled,
        onEditClick
      },
      isDisabled
    } = props

    return (
      <components.MultiValue {...props}>
        <MultiValueContainer>
          <ValueContainer isMulti>
            <span>{label}</span>
          </ValueContainer>
          {!isDisabled && upsertModeEnabled && <ValueActions>
            <ValueAction onClick={(e) => onEditClick(e, props.data)} onTouchStart={(e) => onEditClick(e, props.data)}>
              <Icon icon='pen' />
            </ValueAction>
          </ValueActions>}
        </MultiValueContainer>
      </components.MultiValue>
    )
  },
  IndicatorsContainer: (props) => {
    const { hasValue, selectProps, getValue, isMulti, isDisabled } = props
    const { isLoading, upsertModeEnabled, onAddClick, onEditClick } = selectProps

    return (
      <components.IndicatorsContainer {...props}>
        {!isDisabled && !isLoading && Boolean(upsertModeEnabled) && <>

          {((isMulti) || (!isMulti && !hasValue)) && <>
            <IndicatorContainer onClick={(e) => onAddClick(e)} onTouchStart={(e) => onAddClick(e)}>
              <Icon icon='plus' />
            </IndicatorContainer>

            {/* @ts-ignore */}
            <components.IndicatorSeparator {...props} />
          </>}

          {!isMulti && hasValue && <>
            <IndicatorContainer onClick={(e) => onEditClick(e, getValue()[0])} onTouchStart={(e) => onEditClick(e, getValue()[0])}>
              <Icon icon='pen' />
            </IndicatorContainer>

            {/* @ts-ignore */}
            <components.IndicatorSeparator {...props} />
          </>}
        </>}

        {props.children}
      </components.IndicatorsContainer>
    )

  }
}

export default ResourceSelectComponents
