import store from "../store";
import i18n from "../translations";
import { UserWorkspaceSettingRole, UserWorkspaceSettingScope } from "../types";

export default class UserWorkspaceSettingHelper {
	static SCOPES: UserWorkspaceSettingScope[] = [
		UserWorkspaceSettingScope.DASHBOARD,
		UserWorkspaceSettingScope.CALENDAR,
		UserWorkspaceSettingScope.TASK,
		UserWorkspaceSettingScope.CONTACT,
		UserWorkspaceSettingScope.SEQUENCE,
		UserWorkspaceSettingScope.PLAYBOOK,
		UserWorkspaceSettingScope.PROJECT,
		UserWorkspaceSettingScope.TIME_TRACKING,
		UserWorkspaceSettingScope.TIME_TRACKING_EXPORT,
		UserWorkspaceSettingScope.INVOICE,
		UserWorkspaceSettingScope.INVOICE_EXPORT,
		UserWorkspaceSettingScope.EXPENSE,
		UserWorkspaceSettingScope.EXPENSE_EXPORT,
		UserWorkspaceSettingScope.DOCUMENT,
		UserWorkspaceSettingScope.DOCUMENT_EXPORT,
		UserWorkspaceSettingScope.BANK,
		UserWorkspaceSettingScope.BANK_PAYMENT,
		UserWorkspaceSettingScope.ACCOUNTANT,
		UserWorkspaceSettingScope.PRODUCT,
		UserWorkspaceSettingScope.PRODUCT_EXPORT,
		UserWorkspaceSettingScope.PRODUCT_VIEW_PURCHASE_PRICE,
		UserWorkspaceSettingScope.REPORTING,
		UserWorkspaceSettingScope.SETTING,
		UserWorkspaceSettingScope.FILE
	]

	static hasRole(role: UserWorkspaceSettingRole) {
		const { authentication: { currentUser: { user_workspace_setting } } } = store.getState();

		if (!user_workspace_setting) return false

		if (user_workspace_setting) {
			return user_workspace_setting.role === role
		}
	}

	static hasScope(scope: UserWorkspaceSettingScope) {
		const { authentication: { currentUser: { user_workspace_setting } } } = store.getState();

		// Owners have all access
		if (this.hasRole(UserWorkspaceSettingRole.OWNER)) return true

		if (!user_workspace_setting) return false

		if (user_workspace_setting) {
			return user_workspace_setting.scopes.includes(scope)
		}
	}

	static hasScopes(scopes: UserWorkspaceSettingScope[]) {
		const { authentication: { currentUser: { user_workspace_setting } } } = store.getState();

		// Owners have all access
		if (this.hasRole(UserWorkspaceSettingRole.OWNER)) return true

		if (!user_workspace_setting) return false

		if (user_workspace_setting) {

			return scopes.every(scope => user_workspace_setting.scopes.includes(scope))
		}
	}

	static getRoleTranslation(role: UserWorkspaceSettingRole) {
		switch (role) {
			case UserWorkspaceSettingRole.OWNER:
			case UserWorkspaceSettingRole.MANAGER:
			case UserWorkspaceSettingRole.EMPLOYEE:
				return i18n.t(`UserWorkspaceSettingRole::${role}`)
			default: throw Error(`Unsupported role ${role} given`)
		}
	}
}