import * as React from 'react'
import Badge from '../components/Badge/Badge'
import moment from '../utilities/Moment'
import styled from 'styled-components'
import { Style } from '../styles'
import Icon from '../components/Icons/Icon'
import OgmHelper from './OgmHelper'
import i18n from '../translations'
import store from '../store'
import MobilityHelper from './MobilityHelper'
import { RemittanceInformationType, LedgerItem, LedgerItemStatus, LedgerItemType, LineItem, Locale, SettingTranslateableFields, WorkspaceTax, LedgerItemExportType } from '../types'
import { toFixed } from 'accounting'
import { saveAs } from 'file-saver'

const InvoiceDetailsStatus = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 40px;
	border-radius: ${Style.variables.baseBorderRadius};
	width: 100%;
	font-weight: bold;	
	font-size: 16px;
	background: #D5F0F9;
	color: #007B96;
	margin-bottom: ${Style.spacing.x1};

	&.is-success {
		background: #E1F8E7;
		color: #12B650;

		svg, i {
			color: #12B650;
			fill: #12B650;
		}
	}

	&.is-danger {
		background: #FFEBE6;
		color: #EA1400;

		svg, i {
			color: #EA1400;
			fill: #EA1400;
		}
	}

	&.is-warning {
		background: #FBFAE4;
		color: #B97C00;

		svg, i {
			color: #B97C00;
			fill: #EA1400;
		}
	}

	svg, i {
		margin-right: ${Style.spacing.x1};
		color: #007B96;
		fill: #007B96;
	}

  svg {
    width: 16px;
  }
`


export interface ILedgerItemQualityScoreStats {
  color: string
  score: number
  scoreIndicators: {
    hasBusinessName: boolean
    hasAddressLine: boolean
    hasVatNumber: boolean
    hasAccountNumber: boolean
    hasLogo: boolean
    hasContact: boolean
    hasFootnote: boolean
  }
}

export default class LedgerItemHelper {
  static getFooterDetailsForType(ledgerItemType: LedgerItemType, locale: Locale) {
    let property: keyof SettingTranslateableFields = null

    switch (ledgerItemType) {
      case LedgerItemType.ORDER_FORM:
        property = 'default_order_form_details'
        break
      case LedgerItemType.DELIVERY_NOTE:
        property = 'default_delivery_note_details'
        break
      case LedgerItemType.QUOTATION:
        property = 'default_quotation_details'
        break
      case LedgerItemType.INVOICE:
        property = 'default_invoice_details'
        break
      case LedgerItemType.CREDIT_NOTE:
        property = 'default_credit_note_details'
        break
      case LedgerItemType.RECURRING_INVOICE:
        property = 'default_invoice_details'
        break
    }

    return MobilityHelper.getTranslation(locale, store.getState().authentication.currentUser.workspace.setting.translations, property)
  }

  static getTypeLabel(type: LedgerItemType = LedgerItemType.QUOTATION): string {
    return i18n.t(`LedgerItemType::${type}`)
  }

  static getLedgerItemColor(ledgerItem: LedgerItem) {
    if (ledgerItem.project) {
      return ledgerItem.project.color;
    }
    else if (ledgerItem.contact) {
      return ledgerItem.contact.color;
    }

    return '#ccc';
  }

  static getLedgerItemStatus(ledgerItem: LedgerItem): LedgerItemStatus {
    if (ledgerItem.type === LedgerItemType.ORDER_FORM) {
      const isDelivered = ledgerItem.delivered_on !== null
      const isCancelled = ledgerItem.cancelled_on !== null

      const isOverdue = moment(ledgerItem.due_on).isSameOrBefore(moment(), 'day') && !isDelivered

      if (isDelivered) {
        // Order form has a delivery note attached
        return LedgerItemStatus.DELIVERED
      } else if (isCancelled) {
        // Order form got cancelled
        return LedgerItemStatus.CANCELLED
      } else if (isOverdue) {
        // Order form overdue (needs a delivery note)
        return LedgerItemStatus.OVERDUE
      } else if (ledgerItem.issued_on && ledgerItem.due_on) {
        // Order form is outstanding
        return LedgerItemStatus.OUTSTANDING
      }
      return LedgerItemStatus.DRAFT
    } else if (ledgerItem.type === LedgerItemType.DELIVERY_NOTE) {
      const isInvoiced = ledgerItem.invoiced_on !== null
      const isIssued = ledgerItem.issued_on !== null

      if (isInvoiced) {
        // Delivery note has an invoice attached
        return LedgerItemStatus.INVOICED
      } else if (isIssued) {
        // Delivery note is outstanding
        return LedgerItemStatus.OUTSTANDING
      }

      return LedgerItemStatus.DRAFT
    } else if (ledgerItem.type === LedgerItemType.QUOTATION) {
      const isInvoiced = ledgerItem.invoiced_on !== null
      const isApproved = ledgerItem.approved_on !== null
      const isRejected = ledgerItem.rejected_on !== null
      const isPaid = LedgerItemHelper.isPaid(ledgerItem)
      const isOverdue = LedgerItemHelper.isOverdue(ledgerItem) && !isPaid

      if (isInvoiced) {
        return LedgerItemStatus.INVOICED
      } else if (isApproved) {
        return LedgerItemStatus.APPROVED
      } else if (isRejected) {
        return LedgerItemStatus.REJECTED
      } else if (isOverdue) {
        return LedgerItemStatus.EXPIRED
      } else if (ledgerItem.issued_on && ledgerItem.due_on) {
        return LedgerItemStatus.OUTSTANDING
      }

      return LedgerItemStatus.DRAFT
    } else if (ledgerItem.type === LedgerItemType.PRO_FORMA_INVOICE) {
      const isInvoiced = ledgerItem.invoiced_on !== null
      const isOverdue = LedgerItemHelper.isOverdue(ledgerItem) && !isInvoiced

      if (isInvoiced) {
        return LedgerItemStatus.INVOICED
      } else if (isOverdue) {
        return LedgerItemStatus.EXPIRED
      } else if (ledgerItem.issued_on && ledgerItem.due_on) {
        return LedgerItemStatus.OUTSTANDING
      }

      return LedgerItemStatus.DRAFT
    } else if (ledgerItem.type === LedgerItemType.INVOICE) {
      const isPaid = LedgerItemHelper.isPaid(ledgerItem)
      const isCredited = ledgerItem.credited_on !== null
      const isOverdue = LedgerItemHelper.isOverdue(ledgerItem) && !isPaid

      if (isPaid) {
        return LedgerItemStatus.PAID
      } else if (isCredited) {
        return LedgerItemStatus.CREDITED
      } else if (isOverdue) {
        return LedgerItemStatus.OVERDUE
      } else if (ledgerItem.issued_on && ledgerItem.due_on) {
        return LedgerItemStatus.OUTSTANDING
      }

      return LedgerItemStatus.DRAFT
    } else if (ledgerItem.type === LedgerItemType.CREDIT_NOTE) {
      if (ledgerItem.issued_on) {
        return LedgerItemStatus.CREDITED
      }

      return LedgerItemStatus.DRAFT
    } else if (ledgerItem.type === LedgerItemType.RECURRING_INVOICE) {
      return LedgerItemStatus.SCHEDULED
    } else {
      throw Error(`[getLedgerItemStatus] invalid type ${ledgerItem.type} given.`)
    }
  }

  static getLedgerItemBadge(ledgerItem: LedgerItem): JSX.Element {
    const status = LedgerItemHelper.getLedgerItemStatus(ledgerItem)

    switch (status) {
      case LedgerItemStatus.DRAFT:
        return <Badge type='primary' text={i18n.t('LedgerItemHelper::Draft')} />
      case LedgerItemStatus.OVERDUE:
        return <Badge type='danger' text={i18n.t('LedgerItemHelper::Overdue')} />
      case LedgerItemStatus.APPROVED:
        return <Badge type='success' text={i18n.t('LedgerItemHelper::Approved')} />
      case LedgerItemStatus.REJECTED:
        return <Badge type='danger' text={i18n.t('LedgerItemHelper::Rejected')} />
      case LedgerItemStatus.INVOICED:
        return <Badge type='success' text={i18n.t('LedgerItemHelper::Invoiced')} />
      case LedgerItemStatus.OUTSTANDING:
        return <Badge type='warning' text={i18n.t('LedgerItemHelper::Outstanding')} />
      case LedgerItemStatus.PAID:
        return <Badge type='success' text={i18n.t('LedgerItemHelper::Paid')} />
      case LedgerItemStatus.DELIVERED:
        return <Badge type='success' text={i18n.t('LedgerItemHelper::Delivered')} />
      case LedgerItemStatus.CANCELLED:
        return <Badge type='danger' text={i18n.t('LedgerItemHelper::Cancelled')} />
      case LedgerItemStatus.EXPIRED:
        return <Badge type='danger' text={i18n.t('LedgerItemHelper::Expired')} />
      case LedgerItemStatus.CREDITED:
        return <Badge type='success' text={i18n.t('LedgerItemHelper::Credited')} />
      case LedgerItemStatus.SCHEDULED:
        return <Badge type='success' text={i18n.t('LedgerItemHelper::Scheduled')} />
    }
  }

  static getLedgerItemDetailStatus(ledgerItem: LedgerItem): JSX.Element | null {
    const status = LedgerItemHelper.getLedgerItemStatus(ledgerItem)

    switch (status) {
      case LedgerItemStatus.DRAFT:
        return (
          <InvoiceDetailsStatus>
            <Icon icon='info' />
            {i18n.t('LedgerItemHelper::Draft')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.OVERDUE:
        return (
          <InvoiceDetailsStatus className='is-danger'>
            <Icon icon='exclamation-circle' />
            {i18n.t('LedgerItemHelper::Overdue')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.APPROVED:
        return (
          <InvoiceDetailsStatus className='is-success'>
            <Icon icon='check' />
            {i18n.t('LedgerItemHelper::Approved')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.REJECTED:
        return (
          <InvoiceDetailsStatus className='is-danger'>
            <Icon icon='thumbs-down' />
            {i18n.t('LedgerItemHelper::Rejected')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.INVOICED:
        return (
          <InvoiceDetailsStatus className='is-success'>
            <Icon icon='check' />
            {i18n.t('LedgerItemHelper::Invoiced')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.OUTSTANDING:
        return (
          <InvoiceDetailsStatus className='is-warning'>
            <Icon icon='info' />
            {i18n.t('LedgerItemHelper::Outstanding')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.PAID:
        return (
          <InvoiceDetailsStatus className='is-success'>
            <Icon icon='check' />
            {i18n.t('LedgerItemHelper::Paid')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.DELIVERED:
        return (
          <InvoiceDetailsStatus className='is-success'>
            <Icon icon='check' />
            {i18n.t('LedgerItemHelper::Delivered')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.CANCELLED:
        return (
          <InvoiceDetailsStatus className='is-danger'>
            <Icon icon='exclamation-circle' />
            {i18n.t('LedgerItemHelper::Cancelled')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.EXPIRED:
        return (
          <InvoiceDetailsStatus className='is-danger'>
            <Icon icon='exclamation-circle' />
            {i18n.t('LedgerItemHelper::Expired')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.CREDITED:
        return (
          <InvoiceDetailsStatus className='is-success'>
            <Icon icon='exchange-alt' />
            {i18n.t('LedgerItemHelper::Credited')}
          </InvoiceDetailsStatus>
        )
      case LedgerItemStatus.SCHEDULED:
        return (
          <InvoiceDetailsStatus className='is-success'>
            <Icon icon='calendar' />
            {i18n.t('LedgerItemHelper::Scheduled')}
          </InvoiceDetailsStatus>
        )
    }
  }

  static getLineItemSubTotal(lineItem: LineItem): Number {
    const { quantity, price } = lineItem

    if (price && quantity) {
      return Number(price) * Number(quantity)
    }

    return 0
  }

  static getLineItemNetTotal(lineItem: LineItem): Number {
    const subtotal = this.getLineItemSubTotal(lineItem)

    if (lineItem.discount) {
      return Number(subtotal) - (Number(subtotal) * Number(lineItem.discount))
    } else {
      return subtotal
    }
  }

  static getLineItemTaxTotal(lineItem: LineItem) {
    const netTotal = this.getLineItemNetTotal(lineItem)

    if (lineItem.tax_rate) {
      return (Number(netTotal) * Number(lineItem.tax_rate))
    } else {
      return 0
    }
  }

  static getLineItemTotal(lineItem: LineItem): Number {
    const netTotal = this.getLineItemNetTotal(lineItem)

    if (lineItem.tax_rate) {
      return Number(netTotal) + this.getLineItemTaxTotal(lineItem)
    } else {
      return netTotal
    }
  }

  static getWorkspaceTaxFromRate(rate: string | number, workspaceTaxes: WorkspaceTax[]) {
    const tax = workspaceTaxes.find(workspaceTax => workspaceTax.rate == rate)

    return tax ? tax : null
  }

  static getLedgerItemSubtotal(ledgerItem: LedgerItem) {
    return ledgerItem.line_items.reduce((total, lineItem) => {
      const { quantity, price } = lineItem

      if (isNaN(Number(quantity)) && isNaN(Number(price))) return total

      return Number(total) + Number(LedgerItemHelper.getLineItemSubTotal(lineItem))
    }, 0)
  }

  static getLedgerItemNetTotal(ledgerItem: LedgerItem) {
    return ledgerItem.line_items.reduce((total, lineItem) => {
      const { quantity, price } = lineItem

      if (isNaN(Number(quantity)) && isNaN(Number(price))) return total

      return Number(total) + Number(LedgerItemHelper.getLineItemNetTotal(lineItem))
    }, 0)
  }

  static getLedgerItemDiscountTotal(ledgerItem: LedgerItem) {
    return ledgerItem.line_items.reduce((total, lineItem) => {
      return total + (Number(LedgerItemHelper.getLineItemSubTotal(lineItem)) * Number(lineItem.discount))
    }, 0)
  }

  static getLedgerItemTaxTotal(ledgerItem: LedgerItem) {
    return ledgerItem.line_items.reduce((total, lineItem) => {
      const { quantity, price } = lineItem

      if (
        (isNaN(Number(quantity)) && isNaN(Number(price))) ||
        (lineItem.tax_rate === 0 && lineItem.tax_rate === 0)) {
        return total
      }

      return total + this.getLineItemTaxTotal(lineItem)
    }, 0)
  }

  static getLedgerItemTotal(ledgerItem: LedgerItem) {
    return LedgerItemHelper.getLedgerItemNetTotal(ledgerItem) + LedgerItemHelper.getLedgerItemTaxTotal(ledgerItem)
  }

  /**
   * 
   * @param ledgerItem 
   * @param taxes When not provided treats the ledgerItem as read only and it will calculate the list based on the BTW rates inside the line items
   */
  static getLedgerItemTaxTotalsList(ledgerItem: LedgerItem): { total: number | string, rate: number }[] {
    const taxesList = []

    ledgerItem.line_items.forEach(lineItem => {
      const itemNettotal = LedgerItemHelper.getLineItemNetTotal(lineItem)

      const firstTaxTotal = Number(itemNettotal) * Number(lineItem.tax_rate)
      const firstTaxIndex = taxesList.findIndex(tax => Number(tax.rate) == Number(lineItem.tax_rate))

      // Tax already exists in taxes total list append total
      if (Number(lineItem?.tax_rate) > 0 && firstTaxTotal) {
        if (firstTaxIndex > -1) {
          taxesList[firstTaxIndex].total += firstTaxTotal
        }
        else {
          taxesList.push({ total: firstTaxTotal, rate: Number(lineItem.tax_rate) })
        }
      }
    })
    return taxesList
      .map(tax => ({
        total: Number(toFixed(Number(toFixed(tax.total, 3)), 2)),
        rate: tax.rate
      }))
  }

  static getLedgerItemLocale(ledgerItem: LedgerItem): Locale {
    return (ledgerItem.contact ? ledgerItem.contact.locale : store.getState().authentication.currentUser.workspace.setting.default_ledger_item_locale) as Locale
  }

  static isPaid(ledgerItem: LedgerItem) {
    return ledgerItem.paid_on !== null
  }
  /**
   * Checks if ledger item is overdue based on type
   * @param ledgerItem 
   */
  static isOverdue(ledgerItem: LedgerItem) {
    const type = ledgerItem.type

    if (type === LedgerItemType.ORDER_FORM) {
      return moment().isAfter(moment(ledgerItem.due_on), 'day') && !ledgerItem.delivered_on
    } else if (type === LedgerItemType.DELIVERY_NOTE) {
      return moment().isAfter(moment(ledgerItem.due_on), 'day') && !ledgerItem.invoiced_on
    } if (type === LedgerItemType.QUOTATION) {
      return moment().isAfter(moment(ledgerItem.due_on), 'day') && !ledgerItem.approved_on
    } else if (type === LedgerItemType.INVOICE) {
      return moment().isAfter(moment(ledgerItem.due_on), 'day') && !ledgerItem.paid_on && !ledgerItem.credited_on
    } else if (type === LedgerItemType.CREDIT_NOTE) {
      return false
    } else if (type === LedgerItemType.RECURRING_INVOICE) {
      return false
    }
  }

  static download(ledgerItem: LedgerItem, type: 'pdf' | 'ubl' = 'pdf') {
    if (type === 'pdf' && ledgerItem.pdf_attachment_download_url) {
      saveAs(ledgerItem.pdf_attachment_download_url, ledgerItem.pdf_attachment_file_name)
    } else if (type === 'ubl') {
      saveAs(`/invoices/${ledgerItem.id}/download?type=ubl`)
    }
  }

  static getSuccessiveLedgerNumber(ledgerNumber: string) {
    var alphabet = 'abcdefghijklmnopqrstuvwxyz',
      length = alphabet.length,
      result = ledgerNumber,
      i = ledgerNumber.length,
      index = null

    while (i >= 0) {
      var last = ledgerNumber.charAt(--i),
        next = '',
        carry = false;

      // @ts-ignore
      if (isNaN(last)) {
        index = alphabet.indexOf(last.toLowerCase());

        if (index === -1) {
          next = last;
          carry = true;
        }
        else {
          var isUpperCase = last === last.toUpperCase();
          next = alphabet.charAt((index + 1) % length);
          if (isUpperCase) {
            next = next.toUpperCase();
          }

          carry = index + 1 >= length;
          if (carry && i === 0) {
            var added = isUpperCase ? 'A' : 'a';
            result = added + next + result.slice(1);
            break;
          }
        }
      }
      else {
        // @ts-ignore
        next = +last + 1;
        // @ts-ignore
        carry = next > 9;
        // @ts-ignore
        if (next > 9) {
          // @ts-ignore
          next = 0;
          carry = true
        }

        if (carry && i === 0) {
          result = '1' + next + result.slice(1);
          break;
        }
      }

      result = result.slice(0, i) + next + result.slice(i + 1);
      if (!carry) {
        break;
      }
    }
    return result;
  }

  static getLedgerItemExportTypeOptions() {
    return Object.keys(LedgerItemExportType).map(key => (
      { label: i18n.t(`LedgerItemExportType::${LedgerItemExportType[key]}`), value: LedgerItemExportType[key] }
    ))
  }

  static getLedgerItemStatusLabel(status: LedgerItemStatus): string {
    return i18n.t(`LedgerItemStatus::${status}`)
  }

}

