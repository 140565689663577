import { Locale, MobilityTranslations } from "../types"

export default class MobilityHelper {
  static getTranslation<T>(locale: Locale, translations: MobilityTranslations<T>, property: keyof T): string {
    const localeTranslations = translations[locale]

    if (!localeTranslations) return ''

    return localeTranslations[property as string] || ''
  }

  static updateTranslation<T>(locale: Locale, translations: MobilityTranslations<T>, property: keyof T, value: string): MobilityTranslations<T> {
    const updatedTranslations = { ...translations }

    updatedTranslations[locale as string] = updatedTranslations[locale] || {}
    updatedTranslations[locale][property as string] = value

    return updatedTranslations
  }
}