import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import ResourceCheckbox from './ResourceCheckbox'
import ResourceTableRowData from './ResourceTableRowData'

const Container = styled.tr<{ header?: boolean }>`
	background-color: white;
	border-bottom: 1px solid ${Style.color.border};

	&:last-child {
		border-bottom: 0;
	}

	&:hover {
		background: #f6f6f7;

		${ResourceTableRowData} {
			background: #f6f6f7;
		}
	}

	${props => props.header && css`
		border-bottom: 0;

		&:hover {
			background-color: initial;
		}
	`}
`

interface IProps {
	header?: boolean
	children?: React.ReactNode
	selected?: boolean
	selectionDisabled?: boolean
	onSelectionChange?: (selected: boolean) => void
}

const ResourceTableRow = (props: IProps) => {
	const { header, selected, selectionDisabled, onSelectionChange } = props

	return (
		<Container header={Boolean(header)}>
			{onSelectionChange && <ResourceTableRowData>
				<ResourceCheckbox
					checked={selected}
					disabled={selectionDisabled}
					onCheckedChange={onSelectionChange}
				/>
			</ResourceTableRowData>}
			{props.children}
		</Container>
	)
}

export default ResourceTableRow