import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'
import { Pagination as PaginationType } from '../../types'
import Pagination from '../Pagination/Pagination'

const Container = styled.div`
	padding: ${Style.spacing.x1_5};
	background: white;
	color: #637381;
	text-align: center;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	border-top: 1px solid ${Style.color.border};
`

const FooterLeft = styled.div`
	flex: 1;
`

const FooterRight = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex: 1;
`

const Text = styled.div`
	${(props: { clickable: boolean }) => props.clickable && css`
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	`}
`

interface IProps {
	itemCount: number
	pagination?: PaginationType
	onPageChange?: (page: number) => void
}

export default class ResourceFooter extends React.PureComponent<IProps> {
	render() {
		const { itemCount, pagination, onPageChange } = this.props

		const onPaginationPageChange = (selectedPage: { selected: number }) => {
			const newPage = selectedPage.selected + 1
			if (onPageChange) onPageChange(newPage)
		}

		if (itemCount > 0 && pagination) {
			const { page, pageCount } = pagination
			return (
				<Container>
					<FooterLeft />
					<FooterRight>
						<Pagination
							forcePage={page}
							pageCount={pageCount}
							onPageChange={onPaginationPageChange}
							disableInitialCallback={true}
						/>
					</FooterRight>
				</Container>
			)
		}

		return null
	}
}