import * as React from 'react'
import styled from 'styled-components'
import Loader from '../../Loaders/Loader'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 100px 0;
`

export default () => (<Container> <Loader size='large' /> </Container>)