import * as React from 'react'
import ResourceSelectComponents from './ResourceSelectComponents'
import { components } from 'react-select'
import { SelectComponents } from 'react-select/src/components'
import styled from 'styled-components'
import Icon from '../Icons/Icon'
import MultiValueContainer from './Parts/MultiValueContainer'
import ValueContainer from './Parts/ValueContainer'
import ValueActions from './Parts/ValueActions'
import ValueAction from './Parts/ValueAction'
import OptionContainer from './Parts/OptionContainer'

const DocumentTagSelectComponents: Partial<SelectComponents<any, any>> = {
  ...ResourceSelectComponents,
  Option: (props) => {
    const { data: { label, icon, document_tag, __isNew__, placeholder } } = props

    if (placeholder) return null

    if (!__isNew__) {
      return (
        <components.Option {...props}>
          <OptionContainer>
            <Icon icon='tag' />
            {label}
          </OptionContainer>
        </components.Option>
      )
    } else {
      return (
        <components.Option {...props}>
          {label}
        </components.Option>
      )
    }
  },
  SingleValue: (props) => {
    const { data: { label, icon, document_tag }, isMulti } = props

    return (
      <components.SingleValue {...props}>
        <ValueContainer isMulti={isMulti}>
          <Icon icon='tag' />

          <span>
            {label}
          </span>
        </ValueContainer>
      </components.SingleValue>
    )
  },
  MultiValue: (props) => {
    const {
      data: {
        label,
        document_tag
      },
      selectProps: {
        upsertModeEnabled,
        onEditClick
      },
      isDisabled
    } = props

    return (
      <components.MultiValue {...props}>
        <MultiValueContainer>
          <ValueContainer isMulti>
            <Icon icon='tag' />
            <span>{label}</span>
          </ValueContainer>
          {!isDisabled && upsertModeEnabled && !Boolean(document_tag.type) && <ValueActions>
            <ValueAction onClick={(e) => onEditClick(e, props.data)} onTouchStart={(e) => onEditClick(e, props.data)}>
              <Icon icon='pen' />
            </ValueAction>
          </ValueActions>}
        </MultiValueContainer>
      </components.MultiValue>
    )
  },
}

export default DocumentTagSelectComponents
