import styled from 'styled-components'

const ValueAction = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  height: 100%;

  svg {
    width: 12px;
    height: 12px;
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }

  i {
    font-size: 12px;
  }
`

export default ValueAction