import ERoute from '../ERoute'

export default class RouteHelper {
	static process(route: ERoute, params: object = {}): string {
		let processedRoute = route.toString()
		let processingParams = Object.assign({}, params)

		Object.keys(params).forEach(key => {
			const replaceKey = `:${key}`
			const keyValue = processingParams[key]

			if (processedRoute.includes(replaceKey)) {
				processedRoute = processedRoute.replace(new RegExp(replaceKey, 'g'), keyValue)

				// Remove the key if found and replaced
				delete processingParams[key]
			}
		})

		if (Object.keys(processingParams).length > 0) {
			return `${processedRoute}?${this.encodeQueryData(processingParams)}`
		} else {
			return processedRoute
		}
	}

	static encodeQueryData(data: object): string {
		let ret = [];
		for (let d in data) {
			if (data[d] !== null) {
				ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
			}
		}
		return ret.join('&');
	}
}