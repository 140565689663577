import styled, { css } from "styled-components"
import { Style } from "../../styles"

const ResourceTableRowData = styled.td<{
	textAlign?: 'left' | 'right' | 'center'
	verticalAlign?: 'auto' | 'baseline' | 'bottom' | 'top'
	sticky?: boolean
	stickTop?: number | string
	stickyRight?: number | string
	stickyBottom?: number | string
	stickyLeft?: number | string
	maxWidth?: number | string
	maxWidthMobile?: number | string
	ellipse?: boolean
}>`
	text-align: ${(props) => props.textAlign || 'left'};
	white-space: nowrap;
	padding: ${Style.spacing.x1} ${Style.spacing.x2};
	vertical-align: ${(props) => props.verticalAlign};

	${props => props.onClick && css`
		cursor: pointer;
	`}

	${props => props.sticky && css`
		position: sticky;
		background: white;
		min-width: 50px;
	`}

	${props => props.stickTop && css`
		top: ${props.stickTop};
	`}

	${props => props.stickyRight && css`
		right: ${props.stickyRight};
	`}

	${props => props.stickyBottom && css`
		bottom: ${props.stickyBottom};
	`}

	${props => props.stickyLeft && css`
		left: ${props.stickyLeft};
	`}

	${props => props.maxWidth && css`
		max-width: ${props.maxWidth};
	`}

	${props => props.maxWidthMobile && css`
		@media screen and (max-width: ${Style.breakpoints.SMALL}) {
			max-width: ${props.maxWidthMobile};
		}
	`}

	${props => props.ellipse && css`
		overflow: hidden;
		text-overflow: ellipsis; 
	`}
`

export default ResourceTableRowData