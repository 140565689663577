import * as React from 'react'
import styled, { css } from 'styled-components'
import Icon, { IconTypes } from '../Icons/Icon'

const Container = styled.div`
	position: relative;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-height: 38px;
	padding: 5px 12px;
`

const Wrapper = styled.div`
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	text-transform: none;
	letter-spacing: normal;
	text-rendering: auto;
	z-index: 3;
	width: 100%;
	height: 100%;
	margin: 0;
	appearance: none;
	display: flex;
	justify-content: center;
	align-items: center;
`

const IconContainer = styled.div<{ addMarginRight?: boolean }>`
	display: flex;
	justify-content: center;
	align-items: center;

	${props => props.addMarginRight && css`
		margin-right: 8px;
	`}

	svg {
		width: 16px;
		height: 16px;
	}
`

const Backdrop = styled.div`
	background-color: #c4cdd5;
	border: 1px solid transparent;
	box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
	border-radius: 3px;
	transition-property: box-shadow,background-color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);
	position: absolute;
	z-index: 1;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;

	&::after {
		content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    background: linear-gradient(180deg, #fff, #f9fafb);
    border-radius: 2px;
	}
`

interface IProps {
	icon?: IconTypes
	text?: string
	tip?: string
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const ButtonPanel = (props: IProps) => {
	const { icon, tip, text, onClick } = props
	return (
		<Container onClick={onClick} data-tip={tip}>
			<Wrapper>
				{icon && <IconContainer addMarginRight={Boolean(text)}>
					<Icon icon={icon} />
				</IconContainer>}
				{text && text.length > 0 && text}
			</Wrapper>
			<Backdrop />
		</Container>
	)
}

export default ButtonPanel