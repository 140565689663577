import * as React from 'react'
import styled from 'styled-components'
import Loader from '../Loaders/Loader'

const Container = styled.div`
  position: absolute;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  z-index: 2;
  background-color: hsla(0,0%,100%,.5);
  padding-top: 64px;
`

export default class ResourceSpinner extends React.PureComponent {
  render() {
    return (
      <Container>
        <Loader />
      </Container>
    )
  }
}