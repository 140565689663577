import styled from 'styled-components'

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 0 8px;

  svg {
    margin-right: 8px;
		width: 14px;
		height: 14px;
    min-width: 14px;
    min-height: 14px;
  }

  i {
    font-size: 14px;
    height: 14px;
    width: 14px;
    margin-right: 8px;
  }
`

export default OptionContainer