import styled, { css } from "styled-components"
import { Style } from "../../styles"

const ResourceTableRowHeader = styled.th<{
	sticky?: boolean
	stickyTop?: number | string
	stickyRight?: number | string
	stickyBottom?: number | string
	stickyLeft?: number | string
	maxWidth?: string
}>`
	padding: ${Style.spacing.x1} ${Style.spacing.x2};
	text-align: left;
	font-weight: 800;
	white-space: nowrap;
	border: 0;
	background: #f7f7fa;
	box-shadow: inset 0 -1px 0 ${Style.color.border};

	${props => props.sticky && css`
			position: sticky;
			top: 0;
			background: #f7f7fa;
			z-index: 1;
	`}

	${props => props.maxWidth && css`
		max-width: ${props.maxWidth};
		width: ${props.maxWidth};
	`}

	${props => props.stickyTop && css`
		top: ${props.stickyTop};
	`}

	${props => props.stickyRight && css`
		right: ${props.stickyRight};
	`}

	${props => props.stickyBottom && css`
		bottom: ${props.stickyBottom};
	`}

	${props => props.stickyLeft && css`
		left: ${props.stickyLeft};
	`}

	${props => props.stickyLeft && css`
		left: ${props.stickyLeft};
	`}
`

export default ResourceTableRowHeader