import * as React from 'react'
import ReactPaginate, { ReactPaginateProps } from 'react-paginate'
import styled from 'styled-components'
import { Style } from '../../styles'
import Icon from '../Icons/Icon'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ul {
    display: flex;
    flex-direction: row;
    align-items: center;

    li {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #dbdbdb;
      border-left: none;
      
      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        border-left: 1px solid ${Style.color.border};
        overflow: hidden;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        overflow: hidden;
      }

      &.selected {
        background: #EFF5FF;
        color: #1449D6;
        border: 1px solid #1449D6;

        a:hover {
          background: #EFF5FF;
        }
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #363636;
        cursor: pointer;
        text-align: center;
        outline: none;
        height: 38px;
        width: 38px;
        min-height: 38px;
        min-width: 38px;
        border-radius: 6px;
        
        &:hover {
          background: rgb(249, 250, 251);
        }

        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
`

const Pagination = (props: ReactPaginateProps) => {
  let { forcePage } = props

  forcePage = forcePage !== undefined ? (forcePage - 1) : 0

  return (
    <Container>
      <ReactPaginate
        previousLabel={<Icon icon='chevron-left-hero' />}
        nextLabel={<Icon icon='chevron-right-hero' />}
        marginPagesDisplayed={1}
        {...props}
        forcePage={forcePage}
      />
    </Container>
  )
}

export default Pagination