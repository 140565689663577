import * as React from 'react'
import Icon, { IconTypes } from '../Icons/Icon'

interface IProps {
	icon: IconTypes 
	description: string
	descriptionActionText?: string
	onDescriptionActionClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
	containerClasses?: string
	compact?: boolean
}

export default class CardEmptyInfo extends React.PureComponent<IProps> {
	static defaultProps = {
		compact: false
	}

	render() {
		const { icon, description, descriptionActionText, onDescriptionActionClick, compact } = this.props
		return (
			<div className={`card-empty-info ${compact ? 'is-compact' : ''}`}>
				<Icon icon={icon} className='card-empty-info-icon' />

				<p className='card-empty-info-description'>
					{description}

					{descriptionActionText && <a href='javascript://' onClick={onDescriptionActionClick}>
						{descriptionActionText}
					</a>}
				</p>
			</div>
		);
	}
}