import * as React from 'react'
import Utils from '../../utilities/Utils';
import Icon from '../Icons/Icon';
import { CSSTransition } from 'react-transition-group'

interface IProps {
	checked: boolean,
	label?: string,
	tag?: string,
	onChange?: (checked: boolean) => void,
}

export default class CheckboxInput extends React.PureComponent<IProps> {
	private uniqueId: string

	constructor(props: IProps) {
		super(props);

		this.uniqueId = Utils.uniqueId();

		this.onCheckboxClick = this.onCheckboxClick.bind(this);
	}

	onCheckboxClick(e) {
		const { checked, onChange } = this.props;

		if (onChange) onChange(!checked);
	}

	render() {
		const { label, tag, checked, onChange } = this.props;

		return (
			<label
				className='checkbox'
				htmlFor={this.uniqueId}
				onClick={this.onCheckboxClick}
				style={label ? {} : { maxWidth: 32 }}
			>
				<div className="checkbox-input">
					<div className={`checkbox-check ${label ? 'with-label' : ''}`}>
						<CSSTransition
							timeout={150}
							classNames={{
								enter: 'is-entering',
								enterActive: 'is-entering-active',
								exit: 'is-leaving',
								exitActive: 'is-leaving',
							}}
							in={true}
						>
							{checked ? <Icon icon='check' /> : <span></span>}
						</CSSTransition>
					</div>
					{label && <div className="checkbox-label">
						{label}
					</div>}

					{tag && <div className="checkbox-badge">
						{tag}
					</div>}
				</div>
			</label>
		);
	}
}