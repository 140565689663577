import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import ResourceHelper from '../../helpers/ResourceHelper'
import { AppState } from '../../store'
import { Style } from '../../styles'
import { ResourceFilter, ResourceListFilterPredicate, ResourceListFilterType, Settings } from '../../types'
import moment from '../../utilities/Moment'
import CheckboxInput from '../Form/CheckboxInput'
import DateInput from '../Form/DateInput'
import PowerSelect from '../Form/PowerSelect'
import ReactSelectTheme from '../Form/ReactSelectTheme'
import ResourceCreatablePowerSelect from '../Form/ResourceCreatablePowerSelect'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const InputContainer = styled.div<{ displayArrow?: boolean }>`
  background: url('data:image/svg+xml;base64,PHN2ZyBpZD0iU3ZnanNTdmcxMDAxIiB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmVyc2lvbj0iMS4xIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sbnM6c3ZnanM9Imh0dHA6Ly9zdmdqcy5jb20vc3ZnanMiPjxkZWZzIGlkPSJTdmdqc0RlZnMxMDAyIj48L2RlZnM+PGcgaWQ9IlN2Z2pzRzEwMDgiIHRyYW5zZm9ybT0ibWF0cml4KDEsMCwwLDEsMCwwKSI+PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyODgiIGhlaWdodD0iMjg4Ij48cGF0aCBmaWxsPSIjNDM4OWZjIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zLjggOS42NDZhNC4wMDkgNC4wMDkgMCAwMS0zLjgtNEMwIDQuMDI0Ljk2NiAyLjYyNiAyLjM1NCAyTDQgMy42NDZhMiAyIDAgMTAwIDRoNy41Mkw4LjI5MyA0LjM1M2wxLjQxNC0xLjQxNCA1LjcwNyA1LjcwNy01LjcwNyA1LjcwNy0xLjQxNC0xLjQxNCAzLjIyNy0zLjI5M0gzLjh6IiBjbGFzcz0iY29sb3I2NjZFRTggc3ZnU2hhcGUiPjwvcGF0aD48L3N2Zz48L2c+PC9zdmc+') no-repeat;
  background-position: 5px 10px;
  background-size: 23px;
  padding-left: 28px;
  margin-top: ${Style.spacing.x1_25};
  width: 100%;

  ${props => props.displayArrow === false && css`
    background: initial;
    padding-left: 0;
  `}
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span {
    color: #697386;
  }
`

interface IStateToProps {
  setting: Settings
}

type IProps = {
  resourceListFilter: ResourceFilter
  values?: { [key: string]: any }
  onResourceListFilterPredicateChange: (name: string, predicate: ResourceListFilterPredicate) => void
  onResourceListFilterValueChange: (name: string, value: any) => void
} & IStateToProps

const ResourceFilterInput = (props: IProps) => {
  const { t } = useTranslation()
  const [predicate, setPredicate] = React.useState<ResourceListFilterPredicate | null>(null)
  const { resourceListFilter, values, onResourceListFilterPredicateChange, onResourceListFilterValueChange, setting } = props
  const { name } = resourceListFilter
  const nameWithPredicate = ResourceHelper.getNameWithPredicate(name, predicate)
  const value = values[nameWithPredicate]

  React.useEffect(() => {
    switch (resourceListFilter.type) {
      case ResourceListFilterType.STRING:
        onPredicateChange(ResourceListFilterPredicate.CONTAINS)
        break
      case ResourceListFilterType.SINGLE_OPTION:
        break
      case ResourceListFilterType.MULTIPLE_OPTION:
        break
      case ResourceListFilterType.DATE:
        onPredicateChange(ResourceListFilterPredicate.EQUALS)
        break
      case ResourceListFilterType.NUMBER:
        onPredicateChange(ResourceListFilterPredicate.EQUALS)
        break
      case ResourceListFilterType.BOOLEAN:
        break
    }
  }, [])

  const onPredicateChange = (predicate: string) => {
    const newPredicate = predicate as ResourceListFilterPredicate

    // Set new predicate
    setPredicate(newPredicate)

    // Trigger parent callback
    onResourceListFilterPredicateChange(name, newPredicate)

    if (newPredicate === ResourceListFilterPredicate.PRESENT) {
      onResourceListFilterValueChange(ResourceHelper.getNameWithPredicate(name, newPredicate), String(true))
    }
  }

  switch (resourceListFilter.type) {
    case ResourceListFilterType.STRING:
      const stringPredicateOptions = [
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.CONTAINS}`), value: String(ResourceListFilterPredicate.CONTAINS) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.EQUALS}`), value: String(ResourceListFilterPredicate.EQUALS) },
      ]

      const selectedStringPredicateOption = stringPredicateOptions.find(predicateOption => predicateOption.value === predicate)

      return (
        <Container>
          <PowerSelect
            options={stringPredicateOptions}
            value={selectedStringPredicateOption}
            onChange={(option) => onPredicateChange(option.value)}
          />
          <InputContainer>
            <input
              type='text'
              placeholder={resourceListFilter.label}
              onChange={(e) => {
                const value = e.currentTarget.value

                if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
              }}
              value={value || ''}
            />
          </InputContainer>
        </Container>
      )
    case ResourceListFilterType.SINGLE_OPTION:
      const singleOptions = resourceListFilter.options || []
      let selectedSingleOption = null

      if (value) {
        const valueOption = singleOptions.find(o => o.value === value)

        if (valueOption) {
          selectedSingleOption = valueOption
        }
      }

      return (
        <PowerSelect
          options={singleOptions}
          value={selectedSingleOption}
          placeholder={resourceListFilter.label}
          theme={ReactSelectTheme}
          onChange={(option) => {
            const value = option ? option.value : []

            if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
          }}
        />
      )
    case ResourceListFilterType.SINGLE_RESOURCE:
      return (
        <ResourceCreatablePowerSelect
          type={resourceListFilter.resourceType}
          value={value}
          placeholder={resourceListFilter.label}
          isClearable={true}
          isValidNewOption={resourceListFilter.isValidNewOption}
          params={resourceListFilter.params}
          createParams={resourceListFilter.createParams}
          onChange={(value?: string) => {
            if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
          }}
        />
      )
    case ResourceListFilterType.MULTI_RESOURCE:
      return (
        <ResourceCreatablePowerSelect
          type={resourceListFilter.resourceType}
          value={value}
          placeholder={resourceListFilter.label}
          isClearable={true}
          isValidNewOption={resourceListFilter.isValidNewOption}
          params={resourceListFilter.params}
          createParams={resourceListFilter.createParams}
          onChange={(value?: string) => {
            if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
          }}
          isMulti={true}
        />
      )
    case ResourceListFilterType.MULTIPLE_OPTION:
      const multipleOptions = resourceListFilter.options || []
      let selectedMultipleOption = null

      if (value) {
        const valueOption = multipleOptions.filter(o => value.includes(o.value))

        if (valueOption) {
          selectedMultipleOption = valueOption
        }
      }

      return (
        <PowerSelect
          options={multipleOptions}
          value={selectedMultipleOption}
          // @ts-ignore
          isMulti={true}
          placeholder={resourceListFilter.label}
          theme={ReactSelectTheme}
          onChange={(selectedOptions) => {
            // @ts-ignore
            const value = selectedOptions ? selectedOptions.map(option => option.value) : []

            if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
          }}
        />
      )
    case ResourceListFilterType.DATE:
      const datePredicateOptions = [
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.EQUALS}`), value: String(ResourceListFilterPredicate.EQUALS) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.BETWEEN}`), value: String(ResourceListFilterPredicate.BETWEEN) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_AFTER}`), value: String(ResourceListFilterPredicate.IS_AFTER) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_AFTER_OR_ON}`), value: String(ResourceListFilterPredicate.IS_AFTER_OR_ON) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_BEFORE}`), value: String(ResourceListFilterPredicate.IS_BEFORE) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.IS_BEFORE_OR_ON}`), value: String(ResourceListFilterPredicate.IS_BEFORE_OR_ON) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.PRESENT}`), value: String(ResourceListFilterPredicate.PRESENT) },
      ]
      const selectedDatePredicateOption = datePredicateOptions.find(predicateOption => predicateOption.value === predicate)
      const displayDateArrow = predicate !== ResourceListFilterPredicate.BETWEEN
      const isAfterOrOnDatePredicateName = ResourceHelper.getNameWithPredicate(name, ResourceListFilterPredicate.IS_AFTER_OR_ON)
      const isAfterOrOnDateValue = values[isAfterOrOnDatePredicateName]
      const isBeforeOrOnDatePredicateName = ResourceHelper.getNameWithPredicate(name, ResourceListFilterPredicate.IS_BEFORE_OR_ON)
      const isBeforeOrOnDateValue = values[isBeforeOrOnDatePredicateName]

      const nullPredicateOptions = [
        { label: t(`NullPredicate::Yes`), value: String(true) },
        { label: t(`NullPredicate::No`), value: String(false) },
      ]

      let selectedNullPredicateValue = nullPredicateOptions.find(option => option.value === value)

      return (
        <Container>
          <PowerSelect
            options={datePredicateOptions}
            value={selectedDatePredicateOption}
            onChange={(option) => onPredicateChange(option.value)}
          />
          <InputContainer displayArrow={displayDateArrow}>
            {predicate !== ResourceListFilterPredicate.BETWEEN && predicate !== ResourceListFilterPredicate.PRESENT && <DateInput
              name={resourceListFilter.name}
              dateFormat={setting.date_format}
              timeFormat={false}
              initialValue={value ? moment(value) : null}
              inputProps={{ placeholder: setting.date_format }}
              onChange={(newValue) => {
                if (newValue === '' && onResourceListFilterValueChange) {
                  onResourceListFilterValueChange(nameWithPredicate, null)
                } else {
                  const newDate = moment(newValue)

                  if (newDate.isValid() && onResourceListFilterValueChange) {
                    onResourceListFilterValueChange(nameWithPredicate, newDate.format('YYYY-MM-DD'))
                  }
                }
              }}
              closeOnSelect
            />}

            {predicate === ResourceListFilterPredicate.BETWEEN && <RowContainer>
              <DateInput
                name={isAfterOrOnDatePredicateName}
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={isAfterOrOnDateValue ? moment(isAfterOrOnDateValue) : null}
                inputProps={{ placeholder: setting.date_format }}
                onChange={(newValue) => {
                  if (newValue === '' && onResourceListFilterValueChange) {
                    onResourceListFilterValueChange(isAfterOrOnDatePredicateName, null)
                  } else {
                    const newDate = moment(newValue)

                    if (newDate.isValid() && onResourceListFilterValueChange) {
                      onResourceListFilterValueChange(isAfterOrOnDatePredicateName, newDate.format('YYYY-MM-DD'))
                    }
                  }
                }}
                closeOnSelect
              />

              <span style={{ marginLeft: 8, marginRight: 8 }}>{t('ResourceListFilterInput::and')}</span>

              <DateInput
                name={isBeforeOrOnDatePredicateName}
                dateFormat={setting.date_format}
                timeFormat={false}
                initialValue={isBeforeOrOnDateValue ? moment(isBeforeOrOnDateValue) : null}
                inputProps={{ placeholder: setting.date_format }}
                onChange={(newValue) => {
                  if (newValue === '' && onResourceListFilterValueChange) {
                    onResourceListFilterValueChange(isBeforeOrOnDatePredicateName, null)
                  } else {
                    const newDate = moment(newValue)

                    if (newDate.isValid() && onResourceListFilterValueChange) {
                      onResourceListFilterValueChange(isBeforeOrOnDatePredicateName, newDate.format('YYYY-MM-DD'))
                    }
                  }
                }}
                closeOnSelect
              />

            </RowContainer>}
            {predicate === ResourceListFilterPredicate.PRESENT && <RowContainer>
              <PowerSelect
                options={nullPredicateOptions}
                value={selectedNullPredicateValue}
                styles={{
                  container: (base) => {
                    return {
                      ...base,
                      width: '100%',
                    }
                  }
                }}
                onChange={(option) => {
                  const value = option ? option.value : []

                  if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
                }}
              />
            </RowContainer>}
          </InputContainer>
        </Container>
      )
    case ResourceListFilterType.NUMBER:
      const numberPredicateOptions = [
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.EQUALS}`), value: String(ResourceListFilterPredicate.EQUALS) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.BETWEEN}`), value: String(ResourceListFilterPredicate.BETWEEN) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.GREATER_THAN}`), value: String(ResourceListFilterPredicate.GREATER_THAN) },
        { label: t(`ResourceListFilterPredicate::${ResourceListFilterPredicate.LESS_THAN}`), value: String(ResourceListFilterPredicate.LESS_THAN) },
      ]
      const selectedNumberPredicateOptions = numberPredicateOptions.find(predicateOption => predicateOption.value === predicate)
      const displayNumberArrow = predicate !== ResourceListFilterPredicate.BETWEEN
      const isAfterOrOnNumberPredicateName = ResourceHelper.getNameWithPredicate(name, ResourceListFilterPredicate.IS_AFTER_OR_ON)
      const isAfterOrOnNumberValue = values[isAfterOrOnNumberPredicateName]
      const isBeforeOrOnNumberPredicateName = ResourceHelper.getNameWithPredicate(name, ResourceListFilterPredicate.IS_BEFORE_OR_ON)
      const isBeforeOrOnNumberValue = values[isBeforeOrOnNumberPredicateName]

      return (
        <Container>
          <PowerSelect
            options={numberPredicateOptions}
            value={selectedNumberPredicateOptions}
            onChange={(option) => onPredicateChange(option.value)}
          />
          <InputContainer displayArrow={displayNumberArrow}>
            {predicate !== ResourceListFilterPredicate.BETWEEN && <input
              name={resourceListFilter.name}
              type='number'
              value={value === undefined ? '' : value}
              onChange={(e) => {
                const value = e.currentTarget.value

                if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, value)
              }}
              placeholder='10'
            />}
            {predicate === ResourceListFilterPredicate.BETWEEN && <RowContainer>
              <input
                name={isAfterOrOnNumberPredicateName}
                type='number'
                value={isAfterOrOnNumberValue === undefined ? '' : isAfterOrOnNumberValue}
                onChange={(e) => {
                  const value = e.currentTarget.value

                  if (onResourceListFilterValueChange) onResourceListFilterValueChange(isAfterOrOnNumberPredicateName, value)
                }}
                placeholder='10'
              />

              <span style={{ marginLeft: 8, marginRight: 8 }}>{t('ResourceListFilterInput::and')}</span>

              <input
                name={isBeforeOrOnNumberPredicateName}
                type='number'
                value={isBeforeOrOnNumberValue === undefined ? '' : isBeforeOrOnNumberValue}
                onChange={(e) => {
                  const value = e.currentTarget.value

                  if (onResourceListFilterValueChange) onResourceListFilterValueChange(isBeforeOrOnNumberPredicateName, value)
                }}
                placeholder='10'
              />
            </RowContainer>}
          </InputContainer>
        </Container>
      )
    case ResourceListFilterType.BOOLEAN:
      return (
        <CheckboxInput
          checked={value}
          label={resourceListFilter.label}
          onChange={(checked) => {
            if (onResourceListFilterValueChange) onResourceListFilterValueChange(nameWithPredicate, checked)
          }}
        />
      )
  }
}

const mapStateToProps = (state: AppState): IStateToProps => {
  const {
    authentication: {
      currentUser: {
        workspace: {
          setting
        }
      }
    }
  } = state

  return {
    setting: setting,
  }
}

export default connect(mapStateToProps)(ResourceFilterInput)