import * as React from 'react'
import styled, { css } from 'styled-components'
import { Style } from '../../styles'

interface IProps {
  checked: boolean
  onChange: (checked: boolean) => void
}

const Container = styled.div`
	display: inline-block;
	position: relative;
	width: 14px;
	height: 14px;
  margin-right: ${Style.spacing.x0_5};
`

const Backdrop = styled.span<{ checked: boolean }>`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	pointer-events: none;
	background-color: transparent;
	border: 1px solid transparent;
	box-shadow: 0 0 0 1px transparent, 0 1px 0 0 rgba(22,29,37,.05);
	border-radius: 3px;
	transition-property: box-shadow,background-color;
	transition-duration: .2s;
	transition-timing-function: cubic-bezier(.64,0,.35,1);
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(60 66 87 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(60 66 87 / 8%) 0px 2px 5px 0px;

	${(props: { checked: boolean }) => props.checked && css`
		background-color: ${Style.color.brandPrimary};
	`}

	&::after {
		content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    border-radius: 2px;
	}
`

const CheckboxIcon = styled.span<{ checked: boolean }>`
	position: absolute;
	top: 50%;
	left: 50%;
	transform-origin: 50% 50%;
	pointer-events: none;
	transition: transform .1s cubic-bezier(.36,0,1,1);
	transform: translate(-50%,-50%) scale(0);
	width: 20px;
	height: 20px;

	i, svg {
    display: block;
    height: 20px;
    width: 20px;
    max-height: 100%;
    max-width: 100%;
    margin: auto;
		fill: white;
	}

	${(props: { checked: boolean }) => props.checked && css`
		transform: translate(-50%,-50%) scale(1);
	`}
`

const FilterCheckbox = (props: IProps) => {
  const { checked, onChange } = props

  return (
    <Container onClick={() => onChange(!checked)}>
      <Backdrop checked={checked} />
      <CheckboxIcon checked={checked}>
        <svg viewBox='0 0 20 20' focusable='false' aria-hidden='true'><path d='M8.315 13.859l-3.182-3.417a.506.506 0 0 1 0-.684l.643-.683a.437.437 0 0 1 .642 0l2.22 2.393 4.942-5.327a.437.437 0 0 1 .643 0l.643.684a.504.504 0 0 1 0 .683l-5.91 6.35a.437.437 0 0 1-.642 0'></path></svg>
      </CheckboxIcon>
    </Container>
  )
}

export default FilterCheckbox