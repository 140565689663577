import * as React from 'react'
import { components } from 'react-select'
import { SelectComponents } from 'react-select/src/components'
import styled, { css } from 'styled-components'
import { Contact } from '../../types'
import ContactSelectComponents from './ContactSelectComponents'

const MultiValueWrapper = styled.div<{ present?: boolean }>`
  display: flex;
  flex-direction: row;

  ${props => !props.present && css`
    background-color: #ffeeda;
    color: #856404;
  `}
`

const ContactEmailIndicatorSelectComponents: Partial<SelectComponents<any, any>> = {
  ...ContactSelectComponents,
  MultiValueContainer: (props) => {
    const contact: Contact = props?.data?.contact
    const emailPresent = contact?.emails?.length > 0

    return (
      <components.MultiValueContainer {...props}>
        <MultiValueWrapper present={emailPresent}>
          {props.children}
        </MultiValueWrapper>
      </components.MultiValueContainer>
    )
  },
}

export default ContactEmailIndicatorSelectComponents
