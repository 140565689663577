import styled from 'styled-components'

const IndicatorContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #c4cdd5;
  display: flex;
  padding: 8px;
  transition: color 150ms;
  box-sizing: border-box;
  height: 100%;

  &:hover {
    color: hsl(0,0%,60%);
  }

  svg {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    width: 15px;
    height: 15px;
  }

  i {
    display: inline-block;
    fill: currentColor;
    line-height: 1;
    stroke: currentColor;
    stroke-width: 0;
    font-size: 15px;
  }
`

export default IndicatorContainer