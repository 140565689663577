import { ResourceListFilterPredicate } from "../types";

export default class ResourceHelper {
  static getPredicateAbbreviation(predicate: ResourceListFilterPredicate) {
    switch (predicate) {
      case ResourceListFilterPredicate.CONTAINS:
        return 'cont';
      case ResourceListFilterPredicate.EQUALS:
        return '';
      case ResourceListFilterPredicate.BETWEEN:
        return '';
      case ResourceListFilterPredicate.GREATER_THAN:
        return 'gt';
      case ResourceListFilterPredicate.LESS_THAN:
        return 'lt';
      case ResourceListFilterPredicate.IS_AFTER:
        return 'gt';
      case ResourceListFilterPredicate.IS_AFTER_OR_ON:
        return 'gte';
      case ResourceListFilterPredicate.IS_BEFORE:
        return 'lt';
      case ResourceListFilterPredicate.IS_BEFORE_OR_ON:
        return 'lte';
      case ResourceListFilterPredicate.PRESENT:
        return 'present';
      default: return ''
    }
  }

  static getNameWithPredicate = (name: string, predicate: ResourceListFilterPredicate) => {
    const predicateAbbreviation = ResourceHelper.getPredicateAbbreviation(predicate)

    if (predicateAbbreviation.length > 0) {
      return `${name}[${predicateAbbreviation}]`
    }

    return name
  }
}
