import styled, { css } from 'styled-components'

const ValueContainer = styled.div<{ isMulti?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  i, svg {
    margin-right: 4px;
		width: 14px;
		height: 14px;
    min-width: 14px;
    min-height: 14px;
  }

  span {
    ${props => props.isMulti && css`
      max-width: 250px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}
  }
`

export default ValueContainer